@import "./color-style.scss";

.light-theme{ 
.select-dropdown {
  background: rgba(0, 0, 0, 0.1);
  color: $light-theme-darkgray;
}
.select-dropdown-signup{
  color: black;
}
.close-btn {
  background-color: rgba(0, 0, 0, 0.1);
  color: $light-theme-darkgray;
  &:hover, &:focus, &:active {
    background: rgba(0, 0, 0, 0.6);
    color: white;
  }
  .anticon-close {
    font-size: 10px;
    line-height: 0 !important;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: black;
  font-weight: 500;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: rgba(216, 214, 214, 0.911);
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  color: rgb(0, 0, 0);
  font-weight: 500;
}
.custom-text-clr{
  .ant-modal-confirm-title{
    color:$light-theme-darkgray !important;
  }
  button {
    height: 40px;
    border-radius: 30px;
    padding: 8px 20px;
    font-weight: 700;
    color: $light-theme-darkgray;
    &:first-child {
      color: $light-theme-darkgray !important;
      border: 1px solid $light-theme-darkgray !important;
    }
    &:last-child {
      border: 1px solid $baseYellow !important;
      color: $baseWhite !important;
    }
  }
}
.ant-notification-notice {
  background-color: $baseWhite;
  color: $baseBlack;
}
.ant-notification-notice-message {
  color: $baseBlack;
}
.ant-layout-sider{
background:$baseWhite;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.left-menu .ant-menu-item .ant-btn-link{
    color:rgba( $light-theme-text-color, 0.6);
}
.left-menu {
  background: transparent;
  border-right: none;

  .ant-menu-item {
    font-size: $font-size-base;
    &::after {
      content: none;
    }

    &:hover {
      color: $baseYellow;
    }

    &:active {
      background: transparent !important;
    }

    a {
      &:hover {
        color: $baseYellow;
      }
    }
    .ant-btn-link{
      padding-left: 0;
      color:$light-theme-text-color;
      display: flex;
      align-items: center;
      &:hover,
      &:active,
      &:focus {
        color: $baseYellow;
      }
    }
  }

  .ant-menu-item-selected {
    color: $baseYellow;

    a,.ant-btn-link {
      color: $baseYellow;

      &:active {
        background: transparent !important;
      }
    }
  }
}
.default-btn{
  color: $light-theme-text-color !important;
  border-color: #434343 !important;
}
.ant-radio-inner{
  border-color: #434343 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: red !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: red;
  border-color: red !important;
}

.ant-checkbox-checked {
  background-color: red;
  border-color: red !important;
}

.ant-checkbox:hover {
  border-color: red;
}
.project-title {
    color: $light-theme-text-color;
}
.project-sub-title {
  color: $light-theme-text-color;
}
.ant-picker.ant-picker-disabled {
  background: #ddd;
  border-color: #434343;
  cursor: not-allowed;
}
//silder
.flexslider{
  .rec-arrow{
    color: $light-theme-darkgray;
  }
    .rec-arrow-left{
      background: linear-gradient(267deg, #d6d6d600 0%, #e3e3e3de 100%) 0% 0% no-repeat padding-box !important;
      &.eWjCzc:hover:enabled, 
      &.eWjCzc:focus:enabled,
      &.hJJzxj:hover:enabled, 
      &.hJJzxj:focus:enabled{
        background: linear-gradient(267deg, #d6d6d600 0%, #e3e3e3de 100%) 0% 0% no-repeat padding-box !important;
        box-shadow: none !important;
        color: $light-theme-darkgray;
      }
    }
    .rec-arrow-right{
      background: linear-gradient(267deg, #e3e3e3de 0%, #d6d6d600 100%) 0% 0% no-repeat padding-box !important; 
      &.eWjCzc:hover:enabled, 
      &.eWjCzc:focus:enabled,
      &.hJJzxj:hover:enabled, 
      &.hJJzxj:focus:enabled{
        background: linear-gradient(267deg, #e3e3e3de 0%, #d6d6d600 100%) 0% 0% no-repeat padding-box !important; 
        box-shadow: none !important;
        color: $light-theme-darkgray;
      }
    }
  }
  .slides {
    .slides-items {
      background: $baseWhite 0% 0% no-repeat padding-box;
      box-shadow:none;
      color: $light-theme-text-color;
      &.active {
        background: $baseYellow 0% 0% no-repeat padding-box;
        color: $baseWhite;
      }
    }
  }
  //project details
.project-details-wrapper {
    color:  $baseWhite;
    .title {
        color:  $light-theme-text-color;
      .anticon-close{
        color: $light-theme-text-color;
        cursor: pointer;
      }
    }
  }
  // tab
.custom-tab {
    .ant-tabs-nav {
      background:rgb(255, 255, 255) 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    }
    .ant-tabs-tab {
      color: $light-theme-text-color;
    }
    .ant-tabs-tab-btn:active{
      color: $baseYellow;
    }
  }
  .custom-line{
      background: $light-theme-border-color;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    position: relative;
    padding: 0px 0px;
    padding-left: 40px;
    color: black;
    line-height: 1.5715;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
    height: 112px;
  }
  .border-right{
    border-color: $light-theme-border-color;
  }
  h4.ant-typography, .ant-typography h4{
      color: $light-theme-text-color !important;
  }
  .ant-typography{
    color: $light-theme-text-color !important;
  }
  //card
.custom-card{
    background: $baseWhite 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }
  .ant-table-cell{
    color: $light-theme-text-color;
  }
  .export-link {
    color: $light-theme-text-color;
  
    &:hover {
      color: rgba($light-theme-text-color, 0.8);
    }
  }
  .ant-modal-content{
    backdrop-filter: none;
    background: #F4F5FE;
    color: $light-theme-darkgray;
  }
  .report-table {
    .ant-table-thead{
      background:#F4F5FE ;
    }
  }
  .user-name {
    p {
      color: $baseBlack;
    }
    .ant-avatar {
      background-color:$light-theme-border-color;
    }
  }
  //modal

  .ant-modal {
    .ant-modal-footer{
      .ant-btn{
        color: $light-theme-text-color;
        border-color:  $light-theme-text-color;
      }
      .ant-btn-primary{
        background: $baseYellow;
        color: $baseWhite;
        border-color:  $baseWhite;
      }
    }
    .ant-table-thead {
      font-size: $font-size-base;
      tr{
        th{
          background: transparent !important;
          border-bottom: none !important;
          &.ant-table-cell-scrollbar{
            box-shadow: none;
          }
        }
        .main-title{
          background: rgba( $light-theme-text-color, 0.8) 0% 0% no-repeat padding-box !important;
        }
      }
    }
  tr.ant-table-expanded-row > td{
    background: transparent;
    border-bottom: none;
  }
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    color: black;
}
  .export-excel {
    background-color: rgba( $light-theme-text-color, 0.8) !important;
  }
  .modal-switch {
    margin-left: 307px;
  }

  .timesheetButton{
      color: black;
  }

  .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
    color: white;
}
  .ant-modal-header{
    border-color: $light-theme-border-color;
    .ant-modal-title{
      color:$light-theme-text-color;
    }
  }
  //table
  .ant-table{
    color:$light-theme-text-color; 
  }
  .ant-table-tbody {
    tr.ant-table-row{
      &:nth-child(odd){
        td{
          background: rgba( $baseWhite, 0.6) !important;
        }
      }
    }
  }
//scroll bar

.ant-tabs-dropdown-menu-item {
  color: black;
}

.ant-tabs-dropdown-menu {
  background-color: rgba(189, 187, 187, 0.942);
  box-shadow: none;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(197, 197, 197, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: rgb(209, 209, 209);
  outline: 1px solid rgb(223, 222, 222);
}
//button
.refresh {
  background: transparent;
  color: $light-theme-text-color;
  border-color: $light-theme-text-color;
  &:hover,
  &:focus,
  &:active{
    color: $light-theme-text-color;
    border-color: $light-theme-text-color;
  }
}

//datepicker
.ant-picker-panel-container{
  background: $baseWhite;
}
.ant-picker-header{
  color:$light-theme-text-color;
  border-color:$light-theme-border-color;
  button{
    color: rgba( $light-theme-text-color, 0.6);
    &:hover{
      color: rgba( $light-theme-text-color, 0.9);
    }
  }
  .ant-picker-header-view{
    button{
      color:$light-theme-text-color;
    } 
  }
}
.ant-picker-content{
  th{
    color: rgba( $light-theme-text-color, 0.8);
  }
}
.ant-picker-cell{
  color: rgba( $light-theme-text-color, 0.6);
}
.ant-picker-cell-in-view{
  color: $light-theme-text-color;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{
  color: $light-theme-text-color;
  background: $baseYellow;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
  border-color:$baseYellow;
}
.ant-picker-today-btn{
  color:$light-theme-text-color;
}
.ant-picker-panel {
  border: none;
  .ant-picker-footer{
    border-color:$light-theme-border-color;
  }
}
.ant-form-item-label > label{
  color: $light-theme-text-color;
}
//input
.ant-select-selection-item {
  color:$light-theme-text-color;
}

.ant-select-item-option-content {
  color:$light-theme-text-color;
}
.ant-input,
.ant-picker-focused, .ant-picker {
  color:$light-theme-text-color;
  border: 1px solid $light-theme-border-color !important;
}
.ant-picker-suffix{
  color:$light-theme-text-color;
}
.ant-input:focus, .ant-input:hover, .ant-input-focused{
  border: 1px solid $light-theme-border-color !important;
}
.ant-select-dropdown{
  background: $baseWhite;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background: $lightYellow;
  color: $baseWhite !important;
}
.ant-select-item-option-selected {
  .ant-select-item-option-content{
    background: $lightYellow;
    color: $baseBlack;
  }
}
::placeholder{
  color: rgba( $light-theme-text-color, 0.6) !important;
}
.edit-session {
  color: $light-theme-text-color;
  .ant-picker{
    border: 1px solid $light-theme-border-color !important;
    border-right: none !important;
  }
}
.ant-picker-input > input{
  color:$light-theme-text-color;
}
.top-bar-input{
  color:$light-theme-text-color;
  background:rgba( $light-theme-text-color, 0.2);
}
.ant-avatar{
  background: rgba( $light-theme-text-color, 0.2) !important;
}
.ant-layout-sider-trigger{
  color:$light-theme-text-color;
  background: transparent linear-gradient(180deg, #00000000 0%, #00000036 100%) 0% 0% no-repeat padding-box;
}
td .theme-color{
  color:$light-theme-text-color !important;
}
.poweredby{
  p{
    color:$light-theme-text-color; 
  }
}
//coming soon
.coming-soon{
  background: rgba( $light-theme-text-color, 0.3);
  h1 {
    color:$light-theme-text-color; 
  }

  h2 {
    color:$light-theme-text-color; 
  }
  
  p {
    color:$light-theme-text-color; 
    
  }
}
.tableGroupReport{
  background: #e9e5e5cc !important;
  border-radius: 25px;
}
.ant-empty-normal .ant-empty-image{
  opacity: 0.3;
}
.ant-empty-description{
  color:$light-theme-text-color; 
}


.quality-modal{
  .ant-select-arrow,
  .ant-select-selection-item{
    color: $baseBlack;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
    border: 1px solid;
    border-color: $light-theme-border-color;
  }
}

// login
.login-form{
 
    h1{
      color: $baseBlack;
    }
    .login-form-forgot{
      color: $baseBlack;
    }
    .ant-input{
      height: 50px;
      padding: 6.5px 18px;
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.2);
    }
    .login-form-button{
      height: 50px;
      color: $baseWhite;
      background: $baseYellow;
      border-color: $baseYellow;
      font-weight: 700;
      font-size: $font-size-xxl;
      &.ant-btn:hover, &.ant-btn:focus, &.ant-btn:active {
        color: $baseWhite;
        border-color: $baseYellow;
        background:$deepYellow;
    }
    }
    .password-icon {
      border: 1px solid rgba(112, 112, 112, 0.22) !important;
      background: rgba(255, 255, 255, 0.2);
      .ant-input {
        border: none !important;
        &:hover, &:focus, &:active {
          border: none !important;
        }
      }
    }
}

.signup-form{
 
  h1{
    color: $baseBlack;
  }
  .ant-input{
    height: 50px;
    padding: 6.5px 18px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.2);
  }
  .password-icon {
    border: 1px solid rgba(112, 112, 112, 0.22) !important;
    background: rgba(255, 255, 255, 0.2);
    .ant-input {
      border: none !important;
      &:hover, &:focus, &:active {
        border: none !important;
      }
    }
  }
}
.radio-text {
  color: #000000;
}
.resetPassword{
  font-size: $font-size-lg;
  color: $baseBlack;
}
.ant-switch {
  margin-top: 0.4em !important;
  margin-right: 1em !important;
  background-color: #a6aacd;
}
.ant-switch-checked {
  background-color: #dc172d;
}
.resendMail{
  font-size: $font-size-lg  !important;
  color: $baseBlack !important;
}
.verificationCode input {
  border: 1px solid rgba(112, 112, 112, 0.22) !important;
  caret-color:black;
  color: black;
}
.logo-wrapper{
  .aside-logo-large{
    .logo-black{
      display: block;
    }
    .logo-white{
      display: none;
        }
  }
}

.powered-by-large{
  .logo-black{
    display: block;
  }
  .logo-white{
    display: none;
      }
}
.ant-empty-img-simple-path{ 
  fill: rgb(41, 41, 41) !important;
  stroke: rgb(26, 26, 26) !important;
}
.ant-empty-description{
  color: rgb(46, 46, 46) !important;
}
.ant-empty-img-simple-g{ 
  stroke: rgb(26, 26, 26) !important;
}
.ant-tabs-nav-more{
  .anticon-ellipsis{
    color: #707070;
  }
}
.ant-notification-close-x {
  color: #707070;
}
//spin
.ant-spin{
  color: rgb(46, 46, 46);
}
.small-text {
  font-size: 13px;
  color: #000000;
}

.ant-select-arrow .anticon > svg {
  vertical-align: top;
  color: black;
}

.ant-pagination-item a {
  color: #000000;
}

.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.723);
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  color: #000000;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: rgba(0, 0, 0, 0.448);
}

.cst-pagination {
  .ant-pagination-item-active a, 
  .ant-pagination-item a, 
  .ant-pagination-item:focus a, 
  .ant-pagination-item:hover a, .ant-pagination-item-link {
    color: $light-theme-darkgray !important;
  }
  .ant-pagination-item-active {
    border-color: $light-theme-darkgray !important;
  }
}
.ant-tooltip-inner{
  background:rgb(177, 177, 179);
  box-shadow: none;
}
.ant-tooltip-arrow-content{
  background:rgb(177, 177, 179);
}
.sbd-search-wrapper {
  .ant-input-affix-wrapper {
    background: #FFFFFF !important;
    border: 1px solid rgba(112, 112, 112, 0.4) !important;
    
  }
      .ant-input {
        border: none !important;
      }
}
.quality-modal .ant-modal-footer .ant-btn[disabled]{
  color:#34343e9e;
  border-color: #34343e9e;
}

.sbd-badge {
  &.badge-white {
    background: #34343E;
  }
}
.sbd-info{
  color: #34343E;
}
.ant-radio-wrapper {
  color: #000000;
}
.fsa-personal {
  .ant-table.ant-table-bordered {
    border: 1px solid #4646467b;
    .ant-table-container {
      .ant-table-content>table>thead>tr {
        th {
          border-color: #4646467b !important;
          border-bottom: 1px solid #4646467b !important;

        }

      }

      .ant-table-content>table>tbody>tr {
        td {
          border-color: #4646467b !important;
        }

        
      }
    }
  }
}
.border-none{
  border: none !important;
  &:hover,
  &:focus{
    border: none !important;

  }
}
.ant-input-clear-icon{
  color: rgba(10, 10 ,10 ,0.3);
}
.menu-content{
  color: $light-theme-text-color;
}
.ant-upload-list-item-info .anticon-loading .anticon, .ant-upload-list-item-info .ant-upload-text-icon .anticon{
  color:$light-theme-text-color; 
}
.ant-upload-list-item-card-actions .anticon{
  color:$light-theme-text-color; 
}
.ant-btn-text{
  color: $light-theme-text-color;
  border:1px solid #434343 !important;
}
.ant-btn-text[disabled], .ant-btn-text[disabled]:hover, .ant-btn-text[disabled]:focus, .ant-btn-text[disabled]:active{
  color: $light-theme-text-color;
  border:1px solid #434343 !important;
}
.ant-upload{
  color: $light-theme-text-color;
  .ant-btn{
    color: $light-theme-text-color;
  }
}
.breadcrumb{
color: #34343E;
}

.approve-modal{
  color: #34343E;
}
.edit-type{
  color: #000000;
}
.close-type{
  color: #000000;
}
.back-advisory{
  color: #34343E;
  &:hover,
  &:active,
  &:focus {
    color:#34343E;
  }
}
.ant-tree{
  color: #000000a9;
}

}

.light-theme .upload-label{
  color: #34343E;
}

.light-theme .document-modal .ant-select-selection-item-remove{
  color:#000
}

.light-theme .ant-select{
  color:#000
}

.light-theme .ant-upload-list-item-name-icon-count-1{
  color: #000 !important;
}