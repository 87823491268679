// base spectrum
$baseBlack: black;
$baseYellow: #F33E4C;
$baseWhite: #fff;
$baseWhite-transparant: rgba(255, 255, 255, 0.20);
$card-bg-white: rgba(255, 255, 255, 0.15);
$table-bg-white: rgba(255, 255, 255, 0.1);
$text-color-white: #FFFFFF99;
$input-bg: #FFFFFF1A ;
$error-color:#fb303a;

//light theme
$light-theme-darkgray:#34343E;
$light-theme-text-color:#34343E;
$light-theme-border-color:rgba(112, 112, 112, 0.22);
// yellow spectrum
$lightYellow: #fcf3f4;
$deepYellow: #e23544;

//grey spectrum
$Grey: #707070;
$lightGrey: #999999;
$darkGrey: #282c34;

$font-size-base: 14px;
$font-size-lg: $font-size-base + 2px;
$font-size-xl: $font-size-base + 4px;
$font-size-xxl: $font-size-base + 6px;