@import "./color-style.scss";
@import "./support.scss";

.project-title {
  font-size: $font-size-xxl;
  line-height: normal;
  margin-bottom: 0;
}

.project-sub-title {
  font-size: medium;
  line-height: normal;
  margin-bottom: 0;
}

.ant-table {
  background: transparent;
}

.ant-table-cell {
  color: $baseWhite;
}

.ant-select-selection-item {
  color: $baseWhite;
}

.ant-select-item-option-content {
  color: $baseWhite;
}

.ant-input {
  color: $baseWhite;
}

.ant-btn-link {
  color: $deepYellow;
  font-size: medium;
}
.default-btn{
  color: $baseWhite;
  border-color: rgba($baseWhite, 0.5);
}
.ant-radio-inner{
  border-color: rgba($baseWhite, 0.5) !important;
}
.export-link {
  color: $baseWhite;

  &:hover {
    color: rgba($baseWhite, 0.8);
  }
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: none !important;
}

.table-head-yellow {
  tr {
    th {
      color: $baseWhite;
      font-weight: 400;
    }
  }
}

.ant-table-thead {
  font-size: $font-size-base;

  tr {
    th {
      background: transparent;
      border-bottom: none;
      vertical-align: top;
      white-space: nowrap;
      font-weight: 700 !important;

      &.ant-table-cell-scrollbar {
        box-shadow: none;
      }
    }
  }
}

.has-pointer {
  table {
    tr {
      td {
        cursor: pointer;
      }
    }
  }
}

.rangePicker_separator {
  .ant-picker-range-separator {
    align-items: center;
    padding: 0 8px;
    line-height: 1;
    margin-right: 25px;
  }
}

.lem-signature-rangePicker_separator {
  .ant-picker-range-separator {
    align-items: center;
    padding: 0 8px;
    line-height: 1;
    margin-right: 80px;
  }
}

.edit-project-detail {
  cursor: pointer;
}

.edit-project-detail-disable {
  cursor: not-allowed;
}

.ant-form-item-has-error .ant-form-item-explain,
.ant-form-item-has-error .ant-form-item-split {
  color: $error-color;
}

.quality-report-table {
  .ant-table-thead {
    tr {
      th {
        white-space: normal;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        white-space: normal !important;
      }
    }
  }
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: none;
}

.ant-modal {
  .ant-modal-footer {
    border-top: none;
    padding: 0;

    .ant-btn {
      padding: 8px 20px;
      height: 40px;
      font-weight: 700;
      background: transparent;
      color: $baseWhite;
      border-color: $baseWhite;
      border-radius: 30px;
    }

    .ant-btn-primary {
      background: $baseYellow;
      color: $baseWhite;
      border-color: $baseWhite;
    }
  }

  .ant-table {
    background: transparent !important;
  }

  .ant-table-thead {
    font-size: $font-size-base;

    tr {
      th {
        background: transparent !important;
        border-bottom: none !important;

        &.ant-table-cell-scrollbar {
          box-shadow: none;
        }
      }

      .main-title {
        background: #FFFFFF1A 0% 0% no-repeat padding-box !important;
        border-radius: 46px !important;
        color: $baseWhite;
        width: 100%;
      }
    }
  }

  .ant-table-tbody {
    tr.ant-table-row {
      border-radius: 30px;

      &:nth-child(odd) {
        td {
          background: $table-bg-white !important;

        }

        &:hover {
          td {
            background: $table-bg-white !important;
          }
        }
      }

      &:hover>td {
        background: transparent !important;
      }

      td {
        border-bottom: none !important;
        border: none;
      }
    }
  }

}

.ant-table-tbody {
  tr.ant-table-row {
    border-radius: 30px;

    &:nth-child(odd) {
      td {
        background: $table-bg-white !important;
      }

      &:hover {
        td {
          background: $table-bg-white !important;
        }
      }
    }

    &:hover>td {
      background: transparent !important;
    }

    td {
      border-bottom: none;
      vertical-align: top;
    }
  }
}

tr {
  td:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  td:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
}

.ant-table-tbody>tr>td {
  border-bottom: none;
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
}

.ant-select-focused.ant-select-multiple .ant-select-selector {
  box-shadow: none;
}

.ant-drawer-logo {
  background-image: url(/static/media/fsa_logo.cd86a2a2.jpg);
  background-repeat: round;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  margin-top: 0px;
}

.ant-layout {
  background: transparent;
}

.ant-layout-sider {
  background: transparent;
}

.ant-layout-sider-trigger {
  background: transparent linear-gradient(180deg, #00000000 0%, #000000a3 100%) 0% 0% no-repeat padding-box;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-menu-inline::after {
  border-right: none;
}

.left-menu {
  background: transparent;
  border-right: none;

  .ant-menu-item {
    font-size: $font-size-base;

    &::after {
      content: none;
    }

    &:hover {
      color: $baseYellow;
    }

    &:active {
      background: transparent !important;
    }

    a {
      &:hover {
        color: $baseYellow;
      }
    }

    .ant-btn-link {
      padding-left: 0;
      color: $text-color-white;
      display: flex;
      align-items: center;

      &:hover,
      &:active,
      &:focus {
        color: $baseYellow;
      }
    }
  }

  .ant-menu-item-selected {
    color: $baseYellow;

    a,
    .ant-btn-link {
      color: $baseYellow;

      &:active {
        background: transparent !important;
      }
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.logo-container {
  padding-left: 24px;
  padding-top: 24px;

  .aside-logo-small {
    display: none;
  }

  .logo-wrapper {
    max-width: calc(200px - 48px);

    .aside-logo-large {
      width: calc(200px - 48px);

      .logo-black {
        display: none;
      }

      img {
        width: 100%;
      }
    }

    .aside-logo-small {
      width: 39px;

      img {
        width: 30px;
      }
    }
  }
}

.powered-by-large {
  .logo-black {
    display: none;
  }

  .logo-white {
    display: block;
  }
}

.user-name {
  p {
    color: $baseWhite;
  }

  .ant-avatar {
    color: $baseBlack;
  }
}

.first-last-letter {
  display: none;
}

.full-name {
  display: block;
}

.ant-layout-sider-collapsed {
  .aside-logo-small {
    display: block;
    margin: auto;
  }

  .aside-logo-large {
    display: none;
    width: 100%;
  }

  .poweredby,
  .menu-content {
    opacity: 0;
  }

  .first-last-letter {
    display: block;
  }

  .full-name {
    display: none;
  }

  .logo-container {
    padding-left: 0;
    text-align: center;
  }
}

.ant-layout-header {
  background: transparent;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 50px;
}

//silder
.flexslider {
  border-radius: 14px;
  overflow-x: hidden;

  .rec-carousel {
    position: relative;
    overflow: hidden;
  }

  .rec-arrow {
    position: absolute;
    top: 0;
    border: none;
    height: 106px;
    box-shadow: none;
    border-radius: 0;
    color: #ffffff89;
    z-index: 1;
  }

  .rec-arrow-left {
    left: 0;
    background: linear-gradient(267deg, #00000000 0%, #251f01d6 100%) 0% 0% no-repeat padding-box !important;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;

    &.eWjCzc:hover:enabled,
    &.eWjCzc:focus:enabled,
    &.hJJzxj:hover:enabled,
    &.hJJzxj:focus:enabled {
      background: linear-gradient(267deg, #00000000 0%, #251f01d6 100%) 0% 0% no-repeat padding-box !important;
      box-shadow: none !important;
    }
  }

  .rec-arrow-right {
    right: 0;
    background: linear-gradient(267deg, #251f01d6 0%, #00000000 100%) 0% 0% no-repeat padding-box !important;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;

    &.eWjCzc:hover:enabled,
    &.eWjCzc:focus:enabled,
    &.hJJzxj:hover:enabled,
    &.hJJzxj:focus:enabled {
      background: linear-gradient(267deg, #251f01d6 0%, #00000000 100%) 0% 0% no-repeat padding-box !important;
      box-shadow: none !important;
    }
  }

  .rec-slider-container {
    margin-left: 0;
    margin-right: 0;
  }
}

.slides {
  height: 106px;
  overflow: hidden;
  width: 100%;

  .slides-items {
    background: $card-bg-white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
    border-radius: 14px;
    height: 100%;
    list-style: none;
    padding: 15px;
    margin-right: 16px;
    color: $baseWhite;
    font-size: $font-size-base;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.active {
      background: $baseYellow 0% 0% no-repeat padding-box;
      color: $baseWhite;
      font-weight: 600;
    }
  }
}

.disable-slides {
  height: 106px;
  overflow: hidden;
  width: 100%;

  .slides-items {
    background: $card-bg-white 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
    border-radius: 14px;
    height: 100%;
    list-style: none;
    padding: 15px;
    margin-right: 16px;
    color: $baseWhite;
    font-size: $font-size-base;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;

    &.active {
      background: $baseYellow 0% 0% no-repeat padding-box;
      color: $baseWhite;
      font-weight: 600;
    }
  }
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

.sbd-tooltip {
  z-index: 100;
}

.modal-pagination {
  .ant-tabs {
    height: 100%;
  }
}

//project details
.project-details-wrapper {
  font-size: $font-size-base;
  color: $baseWhite;

  .title {
    color: $baseWhite;

    .anticon-close {
      color: $baseWhite;
      cursor: pointer;
    }
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: transparent;
  border: 1px solid #434343;
  border-radius: 2px;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 32px;
  padding: 0 11px;
  background: #FFFFFF1A;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  height: 32px;
}

.table-date-column {
  white-space: nowrap;
}

// tab
.custom-tab {
  font-size: $font-size-base;

  .ant-tabs-nav {
    background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
    border-radius: 30px;
    padding: 6px;

    &::before {
      border-bottom: none;
    }
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab {
    padding: 5px 25px;
    border-radius: 30px;
    font-size: $font-size-lg;
    color: $baseWhite;
    font-weight: 700;
  }

  .ant-tabs-tab-active {
    background: $baseYellow;

    .ant-tabs-tab-btn {
      color: $baseWhite;
      font-weight: 700;
    }
  }

  .ant-tabs-tab-btn:active {
    color: $deepYellow;
  }
}

//divider
.custom-line {
  width: 100%;
  height: 1px;
  background: $Grey;
}

.custom-line-collapse {
  width: 100%;
}

.ant-collapse-content>.ant-collapse-content-box {
  padding: 16px;
  height: 180px !important;
}

.project-detail-collapse {
  .ant-collapse-content>.ant-collapse-content-box {
    padding: 16px;
    height: 128px !important;
  }
}

.project-detail-email-edit {
  color: #fb303a;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  position: relative;
  padding: 0px 0px;
  padding-left: 40px;
  color: rgba(255, 255, 255, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.border-right {
  border-right: 1px solid $Grey;
}

.custom-fill {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}

//card
.custom-card {
  background: $card-bg-white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  padding: 15px;
}

.card-height {
  height: 96px;
}

.theme-color {
  color: $baseWhite !important;
}

.project-name-number {
  overflow: hidden;
  max-width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.create-project {
  background: $baseYellow;
  color: $baseWhite;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  padding: 15px;
  margin-right: 16px;
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  cursor: pointer;
}

.create-project-disable {
  background: rgba($baseYellow, 0.5);
  ;
  color: $baseWhite;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  padding: 15px;
  margin-right: 16px;
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  cursor: not-allowed;
}

.small-text {
  font-size: 13px;
  color: $text-color-white;
}

.export-excel {
  height: 37px !important;
  display: flex;
  align-items: center;
  background-color: #FFFFFF1A !important;
  margin-left: auto;
}

.modal-switch {
  margin-left: 307px
}

.timesheet-modal-switch {
  margin-left: 694px;
}

.timesheet-month-button {
  background: #F33E4C;
  color: white;
}

.timesheet-week-button {
  background: #F33E4C;
  color: white;
}

.timesheet-range-button {
  background: #F33E4C;
  color: white;
}

.card-scroll {
  max-height: 180px;
  overflow: auto;
}

//scroll bar
::-webkit-scrollbar {
  width: 0.8em;
  height: 0.8em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-corner {
  background: rgba(255, 255, 255, 0.17);
}

:focus-visible {
  outline: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(103, 103, 103, 0.4);
  outline: 1px solid rgba(103, 103, 103, 0.4);
  border-radius: 10px;
}

// typo
h4.ant-typography {
  font-weight: 500;
}

//modal
.ant-table-placeholder {
  td {
    border: none !important;
  }
}

.scroll-table {
  height: calc(100vh - 300px);
  overflow: auto;
}

.ant-modal-close {
  display: none;
}

.ant-modal-content {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 14px;
  padding: 2rem;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 30px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .ant-modal-content {
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    background-color: rgba(0, 0, 0, 0);
  }
}

.ant-modal-header {
  background: transparent;
  padding: 0 0 16px 0;
  border-color: #707070;

  .ant-modal-title {
    color: $baseWhite;
  }
}

.align-bottom {
  vertical-align: bottom !important;
}

.hidden-view-btn {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  opacity: 0;
  height: 100%;
}

.ant-table-wrapper {
  height: 100%;

  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-container {
    height: 100%;
  }
}

.table-with-button {
  height: calc(100% - 40px);
}

.table-with-button-lem {
  height: calc(100% - 60px);
}

.table-with-summary {
  height: calc(100% - 13px);
}

.ant-table-thead th.ant-table-column-sort {
  background: #ffffff00;
}

.table-with-button-assets {
  height: calc(100% - 80px);
}

.tableGroupReport {
  background: #ffffff1a !important;
  border-radius: 25px;
}

//chart
.chart-wrapper {
  >* {
    height: 100% !important;
  }
}

//@at-root
.edit-session {
  height: 32px;
  align-items: center;

  .ant-picker {
    background: $input-bg !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    height: 32px;
  }

  .ant-btn-primary {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 45px;
  }

  .ant-input {
    max-width: 150px;
  }

  .ant-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .ant-input-group-compact {
    .ant-input {
      height: 32px;
    }
  }

  .ant-input-group>.ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }

  .ant-input-group.ant-input-group-compact>*:last-child {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}

//   .asset-search{
//     height: 32px;
//     align-items: center;
//     .ant-picker{
//       background: $input-bg !important;
//       border-top-right-radius: 0;
//       border-bottom-right-radius: 0;
//       border-top-left-radius: 14px;
//       border-bottom-left-radius: 14px;
//       height: 32px;
//     }
//     .ant-btn-primary{
//       border-top-right-radius:14px;
//       border-bottom-right-radius:14px;
//       border-top-left-radius: 0;
//       border-bottom-left-radius: 0;
//       width: 45px;
//     }
//     .ant-input{
//       max-width: 150px;
//     }
//     .ant-empty{
//       display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     }
//     .ant-input-group-compact{
//      .ant-input{
//        height: 32px;
//      }
//     }

//     .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
//       border-top-left-radius:14px;
//       border-bottom-left-radius:14px;
//   }
//   .ant-input-group.ant-input-group-compact > *:last-child{
//     border-top-right-radius:14px;
//       border-bottom-right-radius:14px;
//   }
//   .ant-input-search-enter-button + .ant-input-group-addon, .ant-input-search-enter-button input + .ant-input-group-addon {
//     border-top-right-radius:14px;
//       border-bottom-right-radius:14px;
// }
//   }
.lem-date-filter {
  height: 32px;
  width: 325px;
  align-items: center;

  .ant-picker {
    background: $input-bg !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    height: 32px;
    width: 86%;
  }

  .ant-btn-primary {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 45px;
  }

  .ant-input {
    max-width: 150px;
  }

  .ant-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .ant-input-group-compact {
    .ant-input {
      height: 32px;
    }
  }


  .ant-input-group>.ant-input:first-child,
  .ant-input-group-addon:first-child {
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
  }

  .ant-input-group.ant-input-group-compact>*:last-child {
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}

.ant-input {
  border: none;
  background: $input-bg !important;
  border-radius: 20px;
}

.ant-input:focus,
.ant-input:hover,
.ant-input-focused {
  border: none !important;
  box-shadow: none
}

.ant-btn-primary {
  height: 32px;
  background: $baseYellow;
  color: $baseWhite;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background: $baseYellow;
    color: $baseWhite;
  }
}

.rTableCell {
  text-align: left;
}

#components-form-demo-control-ref .ant-btn {
  margin-right: 8px;
}

.quality-modal {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 46px;
    border: none;
    margin: 4px;
  }

  .ant-select-arrow,
  .ant-select-selection-item {
    color: $baseWhite;
    ;
  }

  .ant-form {
    .ant-form-item-label {
      text-align: left;
    }
  }
}

.ant-modal-body {
  padding: 24px 0;
}

.ant-modal-body-adv {
  z-index: 999;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ant-picker-focused,
.ant-picker {
  background: $input-bg;
  border: none;
  box-shadow: none;
  height: 38px;
  border-radius: 20px;
  width: 100%;
}

.ant-form-item-label>label.ant-form-item-required::after {
  display: inline-block;
  margin-right: 4px;
  color: #a61d24;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form-item-label>label.ant-form-item-required::before {
  content: none !important;
}

.search-container {
  height: 50px;
}

.search-wrapper {
  max-width: 380px;
  padding-left: 30px;

  .ant-input {
    height: 32px;
    border-radius: 0;
  }

  .ant-input-search {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: none;
    background: $input-bg !important;

    .ant-input {
      background: transparent !important;
    }
  }

  .ant-input-search-button {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    height: 40px;
  }
}

.search-top {
  border: none;
  color: $baseWhite;
  background: $baseYellow ;

  &:hover,
  &:focus,
  &:active {
    color: $baseWhite;
    background: $baseYellow ;
  }

  .ant-input-search-button {

    height: 32px;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: $baseYellow;
  }
}

.report-table {
  .ant-empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nowrap {
    white-space: nowrap;
  }

  .nowrap-normal {

    white-space: normal !important;

  }

  .comments-cell {
    max-width: 300px;
  }

  .ant-table-thead {
    position: sticky;
    top: -1px;
    z-index: 3;
    background: #392f27;

    tr:first-child {
      th:first-child {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;

      }

      tr:first-child th:last-child {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
}

.ant-table-body {
  overflow: auto !important;
  height: calc(100% - 54px);
}

.data-listing-table {
  overflow: auto;
  max-height: calc(100vh - 400px);
}

.group-table-title {
  .ant-table-body {
    height: calc(100% - 120px);
  }
}

// border: 1px solid #177ddc;
.ant-radio-checked .ant-radio-inner {
  border-color: red !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: red;
}

.ant-radio:hover .ant-radio-inner {
  border-color: red;
}

.ant-radio-inner {
  border-color: #177ddc;
}

.ant-radio-inner:after {
  background-color: #177ddc;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: red;
  border-color: red !important;
}

.ant-checkbox-checked {
  background-color: red;
  border-color: red !important;
}

.ant-checkbox:hover {
  border-color: red;
}

.ant-checkbox-inner {
  border-color: #177ddc !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  // background-color: red;
  border-color: red !important;
}


.ant-table-tbody>tr.ant-table-row-selected>td {
  background: #ffffff00;
  border-color: rgba(0, 0, 0, 0.03);
}

.asset-collapse {
  height: calc(100% - 80px);
  background: none;

  .ant-collapse-content .ant-collapse-content-box {
    padding: 16px;
    height: 268px !important;
  }

  .ant-collapse-item .ant-collapse-header {
    line-height: 3.3 !important;
  }
}

.asset-collapse-single {
  height: calc(100% - 80px);
  background: none;

  .ant-collapse-content .ant-collapse-content-box {
    padding: 16px;
    height: 334px !important;
  }
}

.assetSpin {

  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }

  .ant-table-wrapper {
    height: calc(100% - 80px);
  }
}

.sbd-assets {
  height: calc(100% - 56px);
}

.sbd-assets-lem {
  height: 102%;
}

.green_dot {
  height: 15px;
  width: 15px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.red_dot {
  height: 15px;
  width: 15px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.sbd-search-wrapper {
  .ant-input-affix-wrapper {
    background: #FFFFFF1A !important;
    border: none;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }

  .ant-input {
    background: transparent !important;
    border-radius: 0;
  }

  .ant-input-search-button,
  .ant-input-group-addon {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
}

// Login
.login-bg {
  background: url(../images/loginbg.png);
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;

  &::before {
    content: '';
    background: transparent linear-gradient(180deg, #000000B0 0%, #0000001F 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    height: 100%;
    width: 50%;
  }
}

.login-logo {
  height: 70px;
  display: block;

  img {
    height: 100%;
  }
}

.login-form {
  max-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100%;

  h1 {
    color: $baseWhite;
    font-weight: 700;
  }

  .login-form-forgot {
    color: $baseWhite;

    &:hover {
      color: $baseYellow;
    }
  }

  .ant-input {
    height: 50px;
    padding: 6.5px 18px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.2);
  }

  .login-form-button {
    height: 50px;
    color: $baseYellow;
    border-color: $baseYellow;
    font-weight: 700;
    font-size: $font-size-xxl;

    &.ant-btn:hover,
    &.ant-btn:focus,
    &.ant-btn:active {
      color: $baseBlack;
      border-color: $baseYellow;
      background: $baseYellow;
    }
  }

  .resert-psw {

    a,
    p {
      font-size: $font-size-lg;
    }
  }

  .password-icon {
    border: none !important;
    height: 50px;
    background: $input-bg !important;
    padding: 0;
    border-radius: 30px;

    .ant-input {
      border: none !important;
      background: none !important;
      padding: 6.5px 18px;

      &:hover,
      &:focus,
      &:active {
        border: none !important;
      }
    }

    .ant-input-suffix {
      padding-right: 16px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100%;

  h1 {
    color: $baseWhite;
    font-weight: 700;
  }

  .ant-input {
    height: 50px;
    padding: 6.5px 18px;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.2);
  }

  .password-icon {
    border: none !important;
    height: 50px;
    background: $input-bg !important;
    padding: 0;
    border-radius: 30px;

    .ant-input {
      border: none !important;
      background: none !important;
      padding: 6.5px 18px;

      &:hover,
      &:focus,
      &:active {
        border: none !important;
      }
    }

    .ant-input-suffix {
      padding-right: 16px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.top-bar-input {
  height: 32px;
  border: none !important;
  background: $input-bg;
  border-radius: 20px;
  min-width: 150px;
  max-width: 250px;
  padding: 0 15px;

  .ant-input {
    border: none !important;
    background: transparent !important;
    border-radius: 0 !important;
  }

  .anticon-close {
    font-size: 12px;
  }

}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.very-number {
  .ant-input {
    width: 45px;
    border-radius: 15px;
  }
}

//coming soon
.coming-soon {
  background: $card-bg-white;
  width: 540px;
  padding: 40px 20px;
  border-radius: 14px;
  text-align: center;


  h1 {
    font-size: 50px;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 700;
  }

  h2 {
    font-size: 29px;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 700;
  }

  .dot {
    color: $baseYellow;
  }

  p {
    text-align: center;
    margin: 18px;
    font-weight: normal;

  }
}

.ant-switch {
  margin-top: 0.4em !important;
  margin-right: 1em !important;
}

.ant-switch-checked {
  background-color: #dc172d;
}

.project-archive {
  .anticon {
    font-size: 20px;
  }
}

//loading
.ant-spin {
  color: $baseWhite;
}

.ant-spin-dot-item {
  background-color: $baseYellow;
}

.ant-input:placeholder-shown {
  font-size: $font-size-base;
}

//button
.refresh {
  background: transparent;
  color: #fff;
  border-color: #fff;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    border-color: #fff;
  }
}
.approval {
  background: transparent;
  color:#38a900;
  border-color:#38a900;

  &:hover,
  &:focus,
  &:active {
    color: #2c8500;
    border-color: #38a900;
  }
}
.input-close {
  right: 15px;
  position: absolute;
  height: 20px;
  color: #ccc3c3;
}

.search-input {
  padding-right: 40px !important;
}

.resetPassword {
  font-size: $font-size-lg;
  color: $baseWhite;
}

.resendMail {
  font-size: $font-size-lg !important;
  color: rgba($baseWhite, 0.5);
}

.ant-empty-img-simple-path {
  fill: White !important;
  stroke: #fff !important;
}

.ant-empty-description {
  color: #fff !important;
}

.ant-empty-img-simple-g {
  stroke: #fff !important;
}

.col-content-inner {
  min-width: 180px;
  padding: 0 8px;
  min-width: 180px;
}

.name-max-width {
  max-width: 180px;
  min-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rec-arrow:disabled {
  opacity: 0.3;
}

@-moz-document url-prefix() {
  .ant-modal-content {
    background-color: #201203;

  }
}

@media (max-width: 1400px) {

  //silder
  .flexslider {
    .rec-arrow {
      height: 96px;
    }
  }

  .slides {
    height: 96px;
  }

  .create-project {
    height: 96px;
  }

  // tab
  .custom-tab {
    .ant-tabs-tab {
      font-size: $font-size-base;
    }
  }

  .tab-content-title {
    h4 {
      font-size: $font-size-lg;
    }

    span {
      line-height: normal;
      font-size: small;
    }
  }

  .custom-card {
    h2 {
      font-size: $font-size-xxl;
    }

    &.card-height {
      height: 84px;
    }
  }
}

@media (max-width: 1200px) {

  //silder
  .flexslider {
    .rec-arrow {
      height: 80px;
    }
  }

  .slides {
    height: 80px;
  }

  .create-project {
    height: 80px;
  }

  // tab
  .tab-content-title {
    h4 {
      font-size: $font-size-base;
    }
  }

  .custom-card {
    padding: 10px;

    h2 {
      font-size: $font-size-xl;
    }

    span {
      font-size: 12px;
    }

    &.card-height {
      height: 75px;
    }
  }

  h4.ant-typography,
  .ant-typography h4 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1023px) {
  .col-content-inner {
    min-width: 180px;
    padding: 0 8px;
  }

  .name-max-width {
    max-width: 180px;
  }
}

.close-btn {
  background: $baseWhite-transparant;
  color: white;
  border: none;
  height: 20px;
  width: 20px;
  min-width: 20px;
  padding: 0;
  margin-left: 5px;

  &:hover,
  &:focus,
  &:active {
    background: $baseWhite-transparant - 10%;
    color: white;
  }

  .anticon-close {
    font-size: 10px;
    line-height: 0 !important;
  }
}

.custom-text-clr {
  button {
    height: 40px;
    border-radius: 30px;
    padding: 8px 20px;
    font-weight: 700;

    &:first-child {
      color: $baseWhite !important;
      border: 1px solid $baseWhite !important;
    }

    &:last-child {
      border: 1px solid $baseYellow !important;
      color: $baseWhite !important;
    }
  }
}

.tag-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
}

.select-dropdown {
  border-radius: 46px;
  background: $baseWhite-transparant;

  .ant-select-selector {
    border: none;
    margin: 4px;
  }
}

.textarea {
  background: $baseWhite-transparant;
}

.cst-pagination {

  .ant-pagination-item-active a,
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a,
  .ant-pagination-item-link {
    color: $baseYellow !important;
  }

  .ant-pagination-item-active {
    border-color: $baseYellow !important;
  }

  justify-content: flex-end;
  display: flex;
}

.sbd-scroll-modal {
  .ant-modal-body {
    height: 60vh;
  }
}

.quality-modal .ant-modal-footer .ant-btn[disabled] {
  color: #ffffff7a;
  border-color: #ffffff7a;
}

.esign-radio {
  .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 7px;
  }
}

.sbd-lem-date-filter {
  padding-left: 37.5rem;
}

.sbd-info {
  color: white;
}

.sbd-badge {
  width: 12px;
  height: 12px;
  margin-right: 6px;
  border-radius: 10px;

  &.badge-green {
    background: #4ded18;
  }

  &.badge-white {
    background: #FFF;
  }

}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: $baseYellow;
}

.create-project-modal {
  height: 460px;
  overflow-y: auto;
  overflow-x: hidden;
}

.fsa-personal {
  .ant-table.ant-table-bordered {

    .ant-table-container {
      border: none !important;

      .ant-table-content>table>thead>tr {
        border-right: none !important;

        th {
          border-color: #464646 !important;
          border-bottom: 1px solid #464646 !important;


          &:last-child {
            border-right: none !important;

          }
        }

      }

      .ant-table-content>table>tbody>tr {
        td {
          border-color: #464646 !important;

          &:last-child {
            border-right: none !important;
          }
        }

        &:nth-child(odd) {
          td {
            background: transparent !important;

          }

          &:hover {
            td {
              background: transparent !important;
            }
          }
        }
      }
    }
  }
}
.border-0{
  border: none;
  &:hover,
&:focus,
&:active{
  border: none;
}
}
.type-card-list{
  height: 100%;
position: relative;
.menu-content{
  min-height:100px ;
  width: 100%;
  height: 100%;
display: flex;
align-items: center;
justify-content: center;
}

  .edit-type{
    position: absolute;
    right: 30px;
    top: 10px;
    z-index: 20;
  }
  .menu-content{
    word-break: break-all;
  }
  .close-type{
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 20;
  }
}

.type-card{
    height: calc(100vh - 150px);
    min-height: 0;
    overflow: auto;
    padding: 0 8px;
}

.loader{
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.advisory-tab{
  .ant-spin-nested-loading,
  .ant-spin-container{
    height: 100%;
  }
}
.ant-modal-body-adv{
  .ant-table-container{
    max-height: 60vh;
    overflow: auto;
  }
}
.advisory-approval{
  .ant-table{
    overflow: auto;
    height: calc(100% - 50px);
  }
  
}

.modal-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fsa-advisory-table{
  height: calc(100% - 100px);
  .ant-table{
    overflow: auto;
    height: auto !important;
    max-height: calc(100% - 100px);

  }

}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected{
  background: transparent;
}

.ant-table-pagination.ant-pagination {
  margin: 7px 7px 0 !important;
}

.select-dropdown-role {
  margin-top: 10px;
  border-radius: 46px !important;
  background: rgba(255, 255, 255, 0.2) !important;
}

.select-dropdown-role .ant-select-selector{
  margin: 4px 0px !important;
  height: 30px !important;
  background: none !important;
  // border: 1px solid #434343 !important;
  // border-radius: 2px !important;
  border: none !important;
}

.light-theme .select-dropdown-role {
  background: rgba(0, 0, 0, 0.1) !important;
  // color: #34343E !important;
}
.document-tag{
  border-radius: 10px;
  margin:2px;
  background: rgba(255, 255, 255, 0.15) 0% 0% no-repeat padding-box;
}

.light-theme .document-tag{
  border-radius: 10px;
  margin:2px;
  background: rgba(255, 255, 255, 0.15) 0% 0% no-repeat padding-box;
  color: #312f2f;
  border-color: #312f2f;
}

.pictureIcon,
.gradeOutPictureIcon {
  border-radius: 25px;
  border: 1px solid;
  padding: 5px;
}

.pictureIcon {
  color: #EF314C;
  background: #00000094;
}

.gradeOutPictureIcon {
  color: #707070;
  background: #00000094;
}