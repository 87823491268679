@import "../global-styles/color-style.scss";

.App-img {
  margin: 10px;
}

.App-header-title {
  display: flex;
  flex: 1;
  justify-content: center;
}

.App-logo {
  height: 10vmin;
  border-radius: 20px;
}

.App-header {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  align-items: center;
}

.App-title {
  text-align: center;
  color: $deepYellow;
}
// common
.h-100{
  height: 100%;
}
.h-109{
  height: 109%;
}
.h-110{
  height: 115px;
}
.d-flex{
  display: flex;
}
.login-image{
  width: 50vw;
  img{
      width: 100%;
      object-fit: cover;
      height: 100%;
      &::after{
          content: "";
          background: transparent linear-gradient(180deg, #00000046 0%, #00000046 100%) 0% 0% no-repeat padding-box;
          position: absolute;
          width: 100%;
          height: 100%;
      }
  }
 
}

.verificationCode{
  width: 16.66666%;
  input{
    border: none;
    background: $input-bg;
    border-radius: 14px;
    height: 50px;
    width: 100%  !important;
    margin: 0 7px;
  }
}