.toggle-container {
  display: inline-block;
  margin-top: 5px;
  
}

.toggle-radio-group {
  display: flex;
  // border: 1px solid #d9d9d9;
  border-radius: 20px;
  overflow: hidden;
  background-color:rgba(255, 255, 255, 0.15);
  height: 25px;
}

.light-theme .toggle-radio-group {
  background-color:#a6aacd;
}

.toggle-radio-button {
  flex: 1;
  border: none;
  border-radius: 20px; /* Adjust border-radius to control the shape of the toggle */
  // text-align: center;
  // font-weight: bold;
  white-space: nowrap;
  font-size: 12px;
  align-items: center;
}

.toggle-radio-button:first-child {
  border-radius: 20px;
}

.toggle-radio-button:last-child {
  border-radius: 20px;
}
/* Apply background color to the checked radio button */
.toggle-radio-button.ant-radio-button-wrapper-checked {
  background-color: #F33E4C !important;
  // background-color: 'transparent';
  color: #fff !important;
  border: none !important;
  height: 25px;
}
.toggle-radio-button.ant-radio-button-wrapper{
  border: none;
  color: #fff !important;
}
.toggle-radio-button.ant-radio-button-wrapper:hover {
  color: #fff !important;
}
.toggle-radio-button.ant-radio-button-wrapper::before,
.toggle-radio-button.ant-radio-button-wrapper::after{
background-color: transparent;
}
.toggle-radio-button.ant-radio-button-wrapper-checked:hover::before,
.toggle-radio-button.ant-radio-button-wrapper-checked:hover::after{
background-color: transparent;
}

.toggle-radio-button.ant-radio-button-wrapper-checked .customIcon{
  background-color: #fff !important;
}

.toggle-radio-button .button-content {
  display: flex;
  align-items: center;
  height: 25px;
}

.toggle-radio-button .anticon {
  margin-right: 8px; /* Adjust margin as needed */
}
