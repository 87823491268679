body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(./images/bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.light-theme {
  background: #F4F5FE;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --amplify-primary-color: #FFD20A;
}

#root {
  height: 100%;
  width: 100%;
}
